import React from "react";

function PlayaV2() {
  return (
    <svg
      width="224.8"
      height="34.3"
      x="0"
      y="0"
      enableBackground="new 0 0 224.8 34.3"
      viewBox="0 0 224.8 34.3"
    >
      <path d="M141 15.2H144.8V19H141z" className="st0"></path>
      <path d="M38.1 15.2H41.9V19H38.1z" className="st0"></path>
      <path d="M182.9 15.2H186.70000000000002V19H182.9z" className="st0"></path>
      <path
        d="M135.3 19.1c1.1 0 1.9-.9 1.9-1.9s-.9-1.9-1.9-1.9c-1.1 0-1.9.9-1.9 1.9s.8 1.9 1.9 1.9"
        className="st0"
      ></path>
      <path d="M102.9 0H106.7V3.8H102.9z" className="st0"></path>
      <path d="M40 22.9H43.8V26.7H40z" className="st0"></path>
      <path d="M47.6 22.9H51.4V26.7H47.6z" className="st0"></path>
      <path d="M55.3 0H59.099999999999994V3.8H55.3z" className="st0"></path>
      <path d="M45.7 7.6H49.5V11.399999999999999H45.7z" className="st0"></path>
      <path d="M83.8 7.6H87.6V11.399999999999999H83.8z" className="st0"></path>
      <path d="M110.5 0H114.3V3.8H110.5z" className="st0"></path>
      <path d="M62.9 0H66.7V3.8H62.9z" className="st0"></path>
      <path d="M28.6 30.5H32.4V34.3H28.6z" className="st0"></path>
      <path d="M59.1 30.5H62.9V34.3H59.1z" className="st0"></path>
      <path
        d="M165.8 22.9H169.60000000000002V26.7H165.8z"
        className="st0"
      ></path>
      <path
        d="M180.9 7.6H184.70000000000002V11.399999999999999H180.9z"
        className="st0"
      ></path>
      <path d="M118.1 0H121.89999999999999V3.8H118.1z" className="st0"></path>
      <path d="M165.8 0H169.60000000000002V3.8H165.8z" className="st0"></path>
      <path
        d="M173.4 22.9H177.20000000000002V26.7H173.4z"
        className="st0"
      ></path>
      <path
        d="M188.5 7.6H192.3V11.399999999999999H188.5z"
        className="st0"
      ></path>
      <path d="M158.1 0H161.9V3.8H158.1z" className="st0"></path>
      <path
        d="M194.3 30.5H198.10000000000002V34.3H194.3z"
        className="st0"
      ></path>
      <path d="M162 30.5H165.8V34.3H162z" className="st0"></path>
      <path
        d="M97.2 3.8c1.1 0 1.9-.9 1.9-1.9 0-1.1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 1.9 0 1.1.8 1.9 1.9 1.9M160 22.9c-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9 1.1 0 1.9-.9 1.9-1.9.1-1.1-.8-1.9-1.9-1.9M57.2 22.9c-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9s1.9-.9 1.9-1.9c0-1.1-.9-1.9-1.9-1.9M89.6 17.1c0 1.1.9 1.9 1.9 1.9s1.9-.9 1.9-1.9-.9-1.9-1.9-1.9-1.9.9-1.9 1.9"
        className="st0"
      ></path>
      <path
        d="M128.8 26.7h25.6v-3.8h-28.6c-1.2-.9-2.6-1.5-4.2-1.8 0-.1.1-.2.1-.3 3.9-.7 7.1-3.8 7.7-7.8 2.3-.7 4-2.9 4-5.4h-3.8c0 1.1-.9 1.9-1.9 1.9-1.1 0-1.9-.9-1.9-1.9H122c0 2.4 1.5 4.5 3.6 5.3-.5 1.9-2.1 3.5-4 4-.6-1.8-2-3.3-3.9-4.2.3-.3.5-.8.5-1.3 0-1.1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 1.9v.2c-.6-.1-1.3-.2-1.9-.2s-1.3.1-1.9.2v-.2c0-1.1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 1.9 0 .5.2.9.5 1.3-1.9 1-3.3 2.5-3.9 4.2-1.9-.5-3.5-2.1-4-4 2.1-.8 3.6-2.9 3.6-5.3h-3.8c0 1.1-.9 1.9-1.9 1.9-1.1 0-1.9-.9-1.9-1.9h-3.8c0 2.5 1.7 4.7 4 5.4.7 3.9 3.8 7.1 7.7 7.8 0 .1.1.2.1.3-1.5.3-3 .9-4.2 1.8h-2.9c-2.5 0-4.9-1-6.7-2.8l-.9-.9c-2.5-2.5-5.9-3.9-9.4-3.9H66.9v3.8h12.4c2.5 0 4.9 1 6.7 2.8l.9.9c2.5 2.5 5.8 3.9 9.3 3.9-.5 1.2-.8 2.5-.8 3.8H95c-2.5 0-4.9-1-6.7-2.8l-.9-.9c-2.5-2.5-5.9-3.9-9.4-3.9h-6.4c-2.5 0-4.9-1-6.7-2.8l-.9-.9c-2.5-2.5-5.9-3.9-9.4-3.9H46v3.8h8.6c2.5 0 4.9 1 6.7 2.8l.9.9c2.5 2.5 5.9 3.9 9.4 3.9H78c2.5 0 4.9 1 6.7 2.8l.9.9.1.1h-19v3.8h91.4v-3.8h-28.6c.1-1.4-.2-2.7-.7-3.8m-16.4-11.5c3.1 0 5.7 1.7 5.7 3.8 0 2.1-2.6 3.8-5.7 3.8s-5.7-1.7-5.7-3.8c0-2 2.6-3.8 5.7-3.8m-7.6 9.6h.8c-1.7 1.4-2.7 3.4-2.7 5.7h-3.8c0-3.2 2.5-5.7 5.7-5.7m1.9 5.7c0-2.1 1.9-3.8 4.2-3.8h3c2.3 0 4.2 1.7 4.2 3.8H106.7zm12.5-5.7h.8c3.2 0 5.7 2.6 5.7 5.7h-3.8c0-2.3-1-4.3-2.7-5.7"
        className="st0"
      ></path>
      <path
        d="M179.1 15.2H176c-2.5 0-4.8-1-6.6-2.8l-.9-.9c-2.5-2.5-5.9-3.9-9.4-3.9h-23.8v3.8h23.8c2.5 0 4.9 1 6.7 2.8l.9.9.1.1h-18.3V19H179v-1.9l.1-1.9z"
        className="st0"
      ></path>
      <path d="M70.5 0H91.5V3.8H70.5z" className="st0"></path>
      <path d="M169.6 30.5H190.6V34.3H169.6z" className="st0"></path>
      <path d="M61 7.6H80.1V11.399999999999999H61z" className="st0"></path>
      <path d="M36.2 30.5H55.300000000000004V34.3H36.2z" className="st0"></path>
      <path d="M7.6 15.2H26.700000000000003V19H7.6z" className="st0"></path>
      <path d="M125.8 0H154.4V3.8H125.8z" className="st0"></path>
      <path
        d="M32.4 19.1c1.1 0 1.9-.9 1.9-1.9 0-1.1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 1.9 0 1 .8 1.9 1.9 1.9M1.9 19.1c1.1 0 1.9-.9 1.9-1.9 0-1.1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 1.9 0 1 .9 1.9 1.9 1.9M40 11.4c1.1 0 1.9-.9 1.9-1.9 0-1.1-.9-1.9-1.9-1.9-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9"
        className="st0"
      ></path>
      <path d="M198.2 15.2H217.29999999999998V19H198.2z" className="st0"></path>
      <path
        d="M192.4 15.2c-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9 1.1 0 1.9-.9 1.9-1.9 0-1-.8-1.9-1.9-1.9M222.9 15.2c-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9 1.1 0 1.9-.9 1.9-1.9 0-1-.8-1.9-1.9-1.9M175.3 7.6c-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9 1.1 0 1.9-.9 1.9-1.9 0-1-.9-1.9-1.9-1.9M55.3 7.6c-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9 1.1 0 1.9-.9 1.9-1.9 0-1-.9-1.9-1.9-1.9"
        className="st0"
      ></path>
    </svg>
  );
}

export default PlayaV2;
