import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import Swiper from 'react-id-swiper'
import { Listing, Wrapper, Title } from '../components'
import website from '../../config/website'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import Pattern from '../components/Pattern'
import LogoOne from '../components/LogoOne'
import PlayaV2 from '../components/PlayaV2'

import 'swiper/css/swiper.css';


import * as styles from "../styles/global.module.css"


const Hero = styled.header`
  background-color: var(--section-color);
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  max-height: 900px;
`

const HeroInner = styled(Wrapper)`
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 2rem;
    z-index: 2;
    height: 100%;
    width: 100%;
    text-align: center;
    position: relative;
`

const TextHero = styled.div`
  line-height: 1.4;
  margin-bottom: 2rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  max-width: 500px;
  left: 50%;
  margin-left: -250px;

  @media screen and (max-width: 39.9375em) {
    max-width: 320px;
    margin-left: -160px;
  }


  p {
    line-height: 1.3;
    font-size: 0.8rem;
  }

  h2 {
    font-size: 2.2rem;
    color: #fff;
  }
`

const globalStyle = css`
  :root {
    --section-color: #965f36;
    --swiper-theme-color: #965f36;
    --section-secondary-color: #352213;
  }
`

const GalDes = styled.section`
  padding-bottom: 100px;

  .swiper-container {
    margin-top: 35px;
    max-width: 65rem;
    overflow: inherit;
  }
`

const ZonaWrapper = styled.main`
  .grid-container {
    max-width: 68rem;
  }
`

const Gradient = styled.div`
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom,  rgba(53, 34, 19, 0) 0%, #352213 100%);
`

const Zona = ({ data: { zona, social, posts, projects }, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const SimpleSwiper = () => {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 50,
      centeredSlides: true,
    }
  
    return(
      <Swiper {...params}>
        {zona.data.gallery.map(singleimage => (
          <div key={singleimage.image_single.alt}>
            <div className="align-center text-center">
              <img src={singleimage.image_single.url} />
            </div>
          </div>
        ))}
      </Swiper>
    )
  }

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} />
      <Global styles={globalStyle} />
      <Hero>
        <div className={styles.heroimage} style={{backgroundImage: 'url(' + `${zona.data.hero_image.url}` + ')'}}>
          <Gradient/>

          <HeroInner className="grid-container">
            <TextHero>
              <h2>{zona.data.hero_title.text}</h2>
              <PrismicRichText field={
                    zona.data.hero_description.richText
                  }
                />
            </TextHero>
          </HeroInner>
        </div>
      </Hero>
      <ZonaWrapper id={website.skipNavId}>

        <section className={`${styles.sectionColabs  } grid-container`}>
          <div className={`${styles.colab  } grid-x grid-padding-x`}>
            <div className={`${styles.textCrea  } large-4 medium-4 small-12`} style={{padding: '3rem 0'}}>
              <div className={styles.textColab} style={{padding: '8rem 1.5rem'}} style={{color: '#976036'}}>
                <PrismicRichText field={
                  zona.data.zone_description.richText
                }
                />
              </div>

            </div>
            <div className={`${styles.imageCreator  } large-8 medium-8 small-12`}>
              <div className={styles.imageWrap}>
                <div className={styles.theImage} style={{backgroundImage: 'url(' + `${zona.data.image_description.url}` + ')'}}/>
              </div>
            </div>
          </div>
        </section>

        <section id="experience" className="text-center align-center">
          <h2 style={{color: 'var(--section-color)', marginBottom: '45px'}}>
            {locale === 'es-mx' ? (
              'UNA EXPERIENCIA EVOCATIVA'
            ) : (
              'AN EVOCATIVE EXPERIENCE'
            )}
          </h2>

          <div className={styles.experience}>
            <div className={styles.experienceImageWrap}>
              <div className={styles.experienceImage} style={{backgroundImage: 'url(' + `${zona.data.experience_image.url}` + ')'}}/>
            </div>
            <div className="grid-x grid-container align-center">
              <div className={`${styles.description  } large-5 medium-6 small-10`}>
              <PrismicRichText field={
                    zona.data.experience_description.richText
                  }
                />
              </div>
            </div>
          </div>
        </section>

        <section id="cosmo" style={{backgroundColor: 'var(--section-secondary-color)', color: '#fff', paddingTop: '10rem'}}>
          <div className="grid-container grid-x align-center">
            <div className='large-10 medium-10 small-12 text-center'>
              <h2 style={{color: '#fff'}}>
                {locale === 'es-mx' ? (
                  'COSMOVISIÓN WIXARIKA'
                ) : (
                  'WIXÁRIKA WORLDVIEW'
                )}
              </h2>
              <div className={styles.cosmoDesc}>
              <PrismicRichText field={
                    zona.data.wixarika_description.richText
                  }
                />
              </div>
            </div>
          </div>

          <div>
            <div className={`${styles.wixa  } grid-x grid-container`} style={{height: '670px'}}>
              <div className={styles.wixaImageWrapper}>
                <div className={styles.wixaImage}/>
              </div>
              <div className={styles.wixaVectorWrapper}>
                <div className={styles.patternBg}/>
              </div>
            </div>
          </div>
        </section>

        <GalDes id="galDes" style={{backgroundColor: 'var(--section-secondary-color)', color: '#fff', paddingTop: '10rem'}}>
          <div className="grid-container grid-x align-center">
            <div className='large-10 medium-10 small-12 text-center'>
              <h2 style={{color: '#fff'}}>{locale === 'es-mx' ? (
                'Tu destino'
              ) : (
                'YOUR NEXT DESTINATION'
              )}</h2>
              <div className={styles.cosmoDesc}>
              <PrismicRichText field={
                    zona.data.your_destiny_description.richText
                  }
                />
                </div>
              
            </div>
          </div>

          <SimpleSwiper/>
        </GalDes>

      </ZonaWrapper>
    </>
  )
}

export default Zona

Zona.propTypes = {
  data: PropTypes.shape({
    zona: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ZonaQuery($locale: String!) {
    zona: prismicZona(lang: { eq: $locale }) {
      data {
        experience_description {
          html
          richText
        }
        experience_image {
          url
        }
        gallery {
          image_single {
            alt
            dimensions {
              height
              width
            }
            url
          }
        }
        hero_description {
          html
          richText
        }
        hero_image {
          url
        }
        hero_title {
          text
        }
        image_description {
          url
        }
        wixarika_description {
          html
          richText
        }
        your_destiny_description {
          html
          richText
        }
        zone_description {
          html
          richText
        }
      }
    }
  }
`
